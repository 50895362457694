<template>
  <modal
    id="modalAdicionarPrecos"
    :exibir="exibir"
    @fechar="fecharModal"
    :titulo="titulo"
    :campos="$refs"
    :ocultarRodape="true"
  >
    <div class="container-fluid">
      <div>
        <b-table
          ref="selectableTable"
          @row-selected="adicionarPreco"
          class="limit-overflow"
          head-variant="light"
          :items="precosFiltrados"
          :fields="campos"
          select-mode="multi"
          responsive="sm"
          selectable
          striped
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="blue text-md fas fa-check-square"></i>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <i class="text-md far fa-square"></i>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>

        <div
          class="text-danger text-center my-4"
          v-if="precosFiltrados.length === 0 && form.precos.length > 0"
        >
          <span> {{ $t('ACADEMICO.TODOS_OS_PRECOS_FORAM_ADICIONADOS') }} </span>
        </div>

        <div class="text-right mt-3">
          <b-button variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="confirmarSelecaoDePrecos"
          >
            {{ $t('GERAL.ADICIONAR') }}
          </b-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
// aux e helpers
import helpers from '@/common/utils/helpers';

// components
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'ModalAdicionarPrecos',
  components: {
    Modal,
  },
  props: {
    form: { type: Object, default: Object },
    precos: { type: Array },
    exibir: { type: Boolean, default: true },
  },
  data() {
    return {
      campos: [
        {
          key: 'selected',
          label: this.$t('GERAL.SELECIONADO'),
        },
        {
          key: 'descricao',
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valorBase',
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
      ],
      precosSelecionados: [],
      precosFiltrados: [],
    };
  },
  computed: {
    titulo() {
      return !this.item
        ? this.$t('ACADEMICO.ADICIONAR_PRECO')
        : this.$t('GERAL.EDITAR');
    },
  },
  watch: {
    exibir(novoValor) {
      if (novoValor) {
        this.filtrarPrecos();
      }
    },
  },
  methods: {
    adicionarPreco(items) {
      this.precosSelecionados = items;
    },

    confirmarSelecaoDePrecos() {
      const preçosAtualizados = this.precosSelecionados.map(
        (precoSelecionado) => {
          const formaPagamento = precoSelecionado.formasPagamento[0];
          return {
            ...precoSelecionado,
            formaPagamentoId: formaPagamento ? formaPagamento.id : null,
            formasPagamentoId: [formaPagamento ? formaPagamento.id : null],
          };
        }
      );

      this.form.precos.push(...preçosAtualizados);
      this.fecharModal();
    },

    filtrarPrecos() {
      this.precosFiltrados = this.precos.filter((preco) => {
        return !this.form.precos.some((formPreco) => formPreco.id === preco.id);
      });
    },

    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>

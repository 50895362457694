<template>
  <div>
    <h6 class="d-block mb-3">{{ $t('ACADEMICO.SIMULACAO_PARCELAS') }}</h6>
    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="itens"
      :fields="campos"
      show-empty
      responsive
      striped
      hover
    />
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';

export default {
  props: {
    itens: { type: Array, default: Array },
  },
  data() {
    return {
      campos: [
        {
          key: 'dataVencimento',
          label: this.$t('ACADEMICO.DATA_VENCIMENTO'),
        },
        {
          key: 'competencia',
          label: this.$t('ACADEMICO.COMPETENCIA'),
        },
        {
          key: 'valor',
          label: this.$t('ACADEMICO.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'parcela',
          label: this.$t('ACADEMICO.PARCELA'),
        },
        {
          key: 'descricao',
          label: this.$t('ACADEMICO.HISTORICO'),
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <span class="d-block mb-4">{{
      $t('ACADEMICO.DESCRICAO_FORM_PRECOS')
    }}</span>
    <div class="d-flex justify-content-between align-items-center">
      <h6>{{ $t('ACADEMICO.LISTA_DE_PRECOS') }}</h6>
      <b-button
        v-if="!desativarForm"
        variant="primary"
        class="mb-3 "
        @click="abrirModalAdicionarPrecos"
      >
        {{ $t('ACADEMICO.ADICIONAR_PRECO') }}
      </b-button>
    </div>

    <b-table
      head-variant="light"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      :items="form.precos"
      :fields="campos"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(formasPagamento)="item">
        <b-form-select
          v-model="item.item.formaPagamentoId"
          :options="item.item.formasPagamento"
          value-field="id"
          text-field="descricao"
          required
          @change="trocarFormaPagamento(item.item)"
        ></b-form-select>
      </template>

      <template #cell(removerPreco)="item" v-if="!desativarForm">
        <b-button variant="secondary" @click="removerPreco(item.item)">
          {{ $t('ACADEMICO.REMOVER') }}
        </b-button>
      </template>
    </b-table>

    <!-- MODAL -->
    <modal-adicionar-precos
      :form="form"
      :precos="precos"
      :exibir="mostrarModalAdicionarPrecos"
      @fechar="fecharModalAdicionarPrecos"
    />
  </div>
</template>

<script>
// AUX & HELPES
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// SERVICES
import PrecoService from '@/common/services/precos/preco.service';

//COMPONENTS
import ModalAdicionarPrecos from '../modais/ModalAdicionarPrecos';
// import { InputSelect } from '@/components/inputs';

export default {
  name: 'TabelaPrecosAdicionados',
  components: {
    ModalAdicionarPrecos,
  },
  props: {
    form: { type: Object, default: Object },
    desativarForm: { type: Boolean, default: false },
  },

  data() {
    return {
      campos: [
        {
          key: 'descricao',
          label: this.$t('PRECOS.DESCRICAO'),
        },
        {
          key: 'valorBase',
          label: this.$t('PRECOS.VALOR'),
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'formasPagamento',
          label: this.$t('ACADEMICO.FORMA_PAGAMENTO'),
        },
        {
          key: 'removerPreco',
          label: this.$t('PRECOS.REMOVER_PRECO'),
        },
      ],
      mostrarModalAdicionarPrecos: false,
      precoRetiradoTabela: {},
      precos: [],
    };
  },
  mounted() {
    this.getPrecos();
  },
  methods: {
    // FUNCOES OBTER DADOS
    getPrecos() {
      this.$store.dispatch(START_LOADING);
      PrecoService.listar()
        .then(({ data }) => {
          this.precos = data;
        })
        .catch((err) => {
          this.precos = [];
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNCOES DADOS
    trocarFormaPagamento(preco) {
      const indexDoItem = this.form.precos.findIndex((p) => p.id === preco.id);
      if (indexDoItem !== -1) {
        this.$set(
          this.form.precos[indexDoItem],
          'formaPagamentoId',
          preco.formaPagamentoId
        );
      }
      this.$emit('atualizou-preco'); // Emitindo evento para informar que o preço foi atualizado
    },

    removerPreco(item) {
      const indexDoItem = this.form.precos.findIndex(
        (preco) => preco.id === item.id
      );
      if (indexDoItem !== -1) {
        this.form.precos.splice(indexDoItem, 1);
      }
    },

    /// FUNCOES MODAL
    abrirModalAdicionarPrecos() {
      this.mostrarModalAdicionarPrecos = true;
    },

    fecharModalAdicionarPrecos() {
      this.mostrarModalAdicionarPrecos = false;
    },
  },
};
</script>
